import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { realSolicitudes, solicitudesHistoricData } from '../static-data/aio-table-data';
import { collection, getDocs, addDoc, setDoc, doc, onSnapshot, query, where, limit, getDoc } from "firebase/firestore";
import { db } from '../app/app.module';
import { UtilsService } from './utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import * as $ from 'jquery';
import { WebhooksService } from './webhooks.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class SolicitudesService {

    firestoreSubscription;
    public cache = new Map();
    store: BehaviorSubject<any> = new BehaviorSubject(null);
    public requests: BehaviorSubject<any> = new BehaviorSubject(null);

    requestsToValidate: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(public utils: UtilsService, private http: HttpClient, private localFetch: FetchService) { 
        
    }

    getRequestsToValidate() {
        return this.requestsToValidate.asObservable();
    }

    setRequestsToValidate(o: any) {
        this.requestsToValidate.next(o);
    }

    initSolicitudesToValidate() {
        this.getSolicitudesToValidate().then(d => {
            this.setRequestsToValidate(d);
        })
    }


    getAsyncData(url) {
        
        console.error('cache map: ', this.cache);
        if (this.cache.has(url) && this.cache.get(url)) {
            this.store.next(this.cache.get(url).data);
        }
        let errorInBack = null;
        const load = async () => {
            const response = await this.localFetch.getFetch(url).catch(e => {
                console.error('catch en request: ', e);
                errorInBack = e.lastReasonEx || e;
            });
            if (response && !errorInBack) {
                this.cache.set(url, response);
                this.store.next(response);
            } else {
                this.store.next(null);
                this.cache.set(url, null);
            }
        };
        load();
        return this.store.asObservable();
    }

    getAsyncRequests(userID?) {
        let errorInBack = null;
        const load = async () => {
            const response = await this.getSolicitudes(userID).catch(e => {
                console.error('catch en request: ', e);
                errorInBack = e.lastReasonEx || e;
            });
            if (response && !errorInBack) {
                this.requests.next(response);
            } else {
                this.requests.next(null);
            }
        };
        load();
        return this.requests.asObservable();
    }

    updateRequest(request) {
        let su = this.requests.subscribe(d => {
            console.warn('subscribe: ', d);
        })
    }

    getSolicitudes(userID?, funderID?) {
        if(funderID) {
            return this.localFetch.getFetch(environment.baseUrl + '/funders/' + funderID + '/requests');
        } else if(userID) {
            return this.localFetch.getFetch(environment.baseUrl + '/users/' + userID + '/requests');
        } else {
            return this.localFetch.getFetch(environment.baseUrl + '/requests');
        }
        
    }

    getFilteredRequest(url) {
        return this.localFetch.getFetch(environment.baseUrl + url);
    }

    deleteRequest(id) {
        return this.localFetch.deleteFetch(environment.baseUrl + '/requests/' + id);
    }

    getFunderSolicitudes(funderID) {
        return this.localFetch.getFetch(environment.baseUrl + '/funders/' + funderID + '/requests');
    }

    getSolicitudesToValidate(userID?) {
        return new Promise((resolve, reject) => {
            this.getSolicitudes(userID).then(d => {
                if(d && d.data) {
                    let r = [];
                    for(var i = 0; i < 7; i++) {
                        r.push(d.data[i]);
                    }
                    resolve({data: r})
                } else {
                    reject(null);
                }
            }).catch( e => reject(e));
        })
        if(userID) {
            return this.localFetch.getFetch(environment.baseUrl + '/users/' + userID + '/requests');
        } else {
            return this.localFetch.getFetch(environment.baseUrl + '/requests');
        }
        
    }

    getSolicitudesWithPAgination(userID, ind) {
        if(userID) {
            return this.localFetch.getFetch(environment.baseUrl + '/users/' + userID + '/requests?page=' + ind);
        } else {
            return this.localFetch.getFetch(environment.baseUrl + '/requests?page=' + ind);
        }
    }

    getSolicitudesWithPaginationFromUrl(url) {
        return this.localFetch.getFetch(url);
    }

    getRequestDetails(requestID) {
        return this.localFetch.getFetch(environment.baseUrl + '/requests/' + requestID + '/detail');
    }

    getSolicitudByCode(vcCode) {
        return this.localFetch.freeGetFetch(environment.baseUrl + '/requests/' + vcCode);
    }

    addSolicitud(obj) {
        
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/sms', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                console.error('addSolicitud', e);
                reject(e);
            })
        });
    }

    acceptTerms(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/sms/terms', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    getNewIdOfRequest(id) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/' + id + '/get-new-id', {}).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    retryRequest(id) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/' + id + '/retry', {}).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    fixData(request_id, obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.putFetchWhitoutToken(`${environment.baseUrl}/requests/${request_id}/person`, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    sendOTP(obj) {
        return new Promise((resolve, reject) => {
            let url = '/requests/sms/otp';
            if(environment['scraperMock']) {
                url = '/mockscrapper/requests/sms/otp'
            }
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + url, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    sendLinkBySms(requestID, obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(`${environment.baseUrl}/requests/${requestID}/send-link-sms`, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });

        
    }

    resendOTP(obj) {
        return new Promise((resolve, reject) => {
            let url = '/requests/sms/resend-otp';
            if(environment['scraperMock']) {
                url = '/mockscrapper/requests/sms/resend-otp'
            }
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + url, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    checkService(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/sms/check-service', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    getDocumentsIds(requestID) {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(`${environment.baseUrl}/requests/${requestID}/documents-ids`).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject('No hay archivos asignados a esta solicitud..');
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    uploadFile(obj) {
        return new Promise((resolve, reject) => {
            let url = '/documents/upload-files';
            this.localFetch.postFile(environment.baseUrl + url, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    


}
